<template>
  <div>
    <admin-view v-if="userDetails && userDetails.role === 1"></admin-view>
    <tenant-view v-else-if="userDetails && userDetails.role === 2"></tenant-view>
    <user-view v-else></user-view>
  </div>
</template>
<script>
export default {
  components: {
    'admin-view': () => import(/* webpackChunkName: "admin-view" */ './admin/Dashboard.vue'),
    'tenant-view': () => import(/* webpackChunkName: "tenant-view" */ './tenant/Dashboard.vue'),
    'user-view': () => import(/* webpackChunkName: "user-view" */ './user/Dashboard.vue')
  },
  beforeMount () {
    // console.log(this.userDetails)
  }
}
</script>
